export const authSettings = {
  guest: {
    view: ['forms','formsconfig'],
    edit: ['forms','formsconfig'],
  },
  user: {
    parent: 'guest',
      view: ['forms'],
      create: ['forms'],
  },
  admin: {
    parent: 'user',
      view: ['formsconfig'],
      edit: ['formsconfig'],
  },
  superadmin: {
    parent: 'admin',
      remove: ['*']
  },
};


