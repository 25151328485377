import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import '@angular/common/locales/global/es-AR';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbThemeModule, NbSidebarModule, NbMenuModule, NbToastrModule, NbDatepickerModule, NB_THEME_OPTIONS, NbTimepickerModule, NbWindowModule } from '@nebular/theme';

import { RoleProvider } from './role.provider';
import { NbRoleProvider, NbSecurityModule } from '@nebular/security';
import { authSettings } from './access.settings';

import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { UserServices } from './core/user/services/user.services';

import { NbDateFnsDateModule } from '@nebular/date-fns';

import { environment } from '../environments/environment';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';



const isIE=window.navigator.userAgent.indexOf('MSIE')>-1
||window.navigator.userAgent.indexOf('Trident/')>-1


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    MsalModule.forRoot(new PublicClientApplication(
      {
        auth: {
          clientId: environment.azure.auth.clientId,
          redirectUri: environment.azure.auth.redirectUri,
          authority: 'https://login.microsoftonline.com/' + environment.azure.auth.tenantId,
        },
        cache:{
          cacheLocation: 'localStorage',
          storeAuthStateInCookie:isIE
        }
      }
    ),
    {
      interactionType: InteractionType.Popup, // MSAL Guard Configuration
      authRequest: {
        scopes:['user.read'], 
        extraQueryParameters: {"ui_locales" : "en-US"}
      }
    },
    {
      interactionType: InteractionType.Redirect,  // MSAL Interceptor Configuration
      protectedResourceMap: new Map(
        [
          ['https://graph.microsoft.com/v1.0/me',['user.Read']],
          [environment.apiUrl, [environment.azure.apiScope]]
        ]
      )
    }),
    NbThemeModule.forRoot({ name: 'cnbvra-theme' }),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbWindowModule.forRoot(),
    NbTimepickerModule.forRoot(),
    NbDateFnsDateModule.forRoot({ format: 'dd/MM/yyyy' }),
    NbToastrModule.forRoot(),
    NbSecurityModule.forRoot({
      accessControl: authSettings,
    })
  ],
  providers: [
    {
      provide: LocationStrategy, 
      useClass: PathLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
      MsalGuard, 
      UserServices,
    { 
      provide: NbRoleProvider, 
      useClass: RoleProvider 
    },
    {
      provide: LOCALE_ID, 
      useValue: 'en-US' 
    }
  ],
  bootstrap: [AppComponent,MsalRedirectComponent]
})
export class AppModule { }
