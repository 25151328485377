import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';

import { catchError, Observable, throwError } from 'rxjs';

import { User } from '../interfaces/user'

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0';
const GRAPH_ENDPOINT_PIC = 'https://graph.microsoft.com/v1.0/me/photo/$value';

@Injectable(
  {
    providedIn: 'root'
  }
)

export class UserServices {

    constructor(private http: HttpClient, private _msalService: MsalService) {
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Access-Control-Allow-Origin': '*',
            })
          };
    }

    ////////////////////////////////////
    ////////  User - Services //////////
    ////////////////////////////////////

    getUserProfile(){
        return this.http.get<User>(GRAPH_ENDPOINT + '/me');
    }

    getUsers(){
      return this.http.get<User[]>(GRAPH_ENDPOINT + '/users');
    }

    getProfilePic()
    {
      return this.http.get(GRAPH_ENDPOINT_PIC, {responseType:'blob'});
    }

    getUserRoles()
    {
      let roles = this._msalService.instance.getActiveAccount()?.idTokenClaims?.roles;
      return !(roles === undefined) ? roles : ['']
    }

    errorHandler(error: HttpErrorResponse) {
        return throwError(() => new Error(error.message || 'Server error.'));
    }
}