import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { RoleGuardService } from './role-guard.service';

import { default as auth } from './auth-config.json';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module')
      .then(m => m.HomeModule),
      canActivate:[MsalGuard] 
  },
  {
    path: 'forms',
    loadChildren: () => import('./forms/forms.module')
      .then(m => m.FormsModule),
      canActivate:[MsalGuard] 
  },
  {
    path: 'legajos-digitales',
    loadChildren: () => import('./legajos-digitales/legajos-digitales.module')
      .then(m => m.LegajosDigitalesModule),
      canActivate:[MsalGuard] 
  },
  {
    path: 'recepcion',
    loadChildren: () => import('./recepcion/recepcion.module')
      .then(m => m.RecepcionModule),
      canActivate:[MsalGuard] 
  },
  {
    path: 'compras-comunitarias',
    loadChildren: () => import('./compras-comunitarias/compras-comunitarias.module')
      .then(m => m.ComprasComunitariasModule),
      canActivate:[MsalGuard] 
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home' },
];

const config: ExtraOptions = {
  useHash: true,
  initialNavigation: 'enabledNonBlocking'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
